import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import Help from '../../assets/help.svg'
import { getDsgTokenSourcesAssets, getTrustwalletAssets } from '../../constants/index.js'


const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const BAD_SRCS = {}

export default function TokenLogo({ address, header = false, size = '24px', ...rest }) {

  const [, refresh] = useState(0)

  const srcs = useMemo(() => {
    return [
      ...getDsgTokenSourcesAssets(address),
      ...getTrustwalletAssets(address),
      Help,
    ]
  }, [address])
  const src = srcs.find((s) => !BAD_SRCS[s])
  
  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={src}
        size={size}
        onError={() => {
          console.log(src)
          if (src) BAD_SRCS[src] = true
          refresh((i) => i + 1)
        }}
      />
    </Inline>
  )
}
