import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import getRpcUrl from '../utils/getRpcUri'

const RPC_URL = getRpcUrl()

export const client = new ApolloClient({
  link: new HttpLink({
    uri: RPC_URL,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/sameepsi/maticblocks',
  }),
  cache: new InMemoryCache(),
})
