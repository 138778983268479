import random from 'lodash/random'

// Array of available nodes to connect to
const GRAPH_URL1 = "https://api.thegraph.com/subgraphs/name/magicballswap/swap"
const GRAPH_URL2 = "https://api.thegraph.com/subgraphs/name/vbm290/swapts1"
const GRAPH_URL3 = "https://api.thegraph.com/subgraphs/id/QmbyN3YyahoTxVLQmUYtkmaBimhY1CjGPWjV1442QcE68Y"
export const nodes = [GRAPH_URL1]

const getNodeUrl = () => {
  // const randomIndex = random(0, nodes.length - 1)
  return nodes[0]
}

export default getNodeUrl